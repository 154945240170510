.heading {
  text-align: center;
  letter-spacing: 1.5px;
  width: 100%;
  margin-top: 100px;
}

.main_headline {
  font-size: 44px;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.sub_headline {
  margin: 0 auto;
  max-width: 650px;
  font-size: 16px;
  line-height: 2;
  position: relative;
  display: block;
  animation: transitionIn 2.5s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
