.navigation_list {
  display: block;
  text-align: center;
  margin-top: 30px;
  padding: 0;
}

.navigation_item {
  display: inline-block;
  margin: 0 2px;
}

/*=icons*/
.github-icon,
.envelope-icon {
  color: white;
  display: inline;
  padding: 10px;
  animation: transitionIn 3s;
}

.github-icon:hover,
.envelope-icon:hover {
  color: #c8c8c8;
  cursor: pointer;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
