@import url('https://fonts.googleapis.com/css?family=Poppins:200&display=swap');

body {
  background-color: #282828;
  color: white;
  font-family: 'Poppins', sans-serif;
  animation: transitionIn 1s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
