@import url(https://fonts.googleapis.com/css?family=Poppins:200&display=swap);
.Header_heading__3BwOQ {
  text-align: center;
  letter-spacing: 1.5px;
  width: 100%;
  margin-top: 100px;
}

.Header_main_headline__2Uhnn {
  font-size: 44px;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.Header_sub_headline__1phXv {
  margin: 0 auto;
  max-width: 650px;
  font-size: 16px;
  line-height: 2;
  position: relative;
  display: block;
  -webkit-animation: Header_transitionIn__2e7TH 2.5s;
          animation: Header_transitionIn__2e7TH 2.5s;
}

@-webkit-keyframes Header_transitionIn__2e7TH {
  from {
    opacity: 0;
    -webkit-transform: rotateX(-10deg);
            transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
}

@keyframes Header_transitionIn__2e7TH {
  from {
    opacity: 0;
    -webkit-transform: rotateX(-10deg);
            transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
}

.Navigation_navigation_list__3TBw9 {
  display: block;
  text-align: center;
  margin-top: 30px;
  padding: 0;
}

.Navigation_navigation_item__3Uidm {
  display: inline-block;
  margin: 0 2px;
}

/*=icons*/
.Navigation_github-icon__1IVW6,
.Navigation_envelope-icon__H-esU {
  color: white;
  display: inline;
  padding: 10px;
  -webkit-animation: Navigation_transitionIn__Tj_6e 3s;
          animation: Navigation_transitionIn__Tj_6e 3s;
}

.Navigation_github-icon__1IVW6:hover,
.Navigation_envelope-icon__H-esU:hover {
  color: #c8c8c8;
  cursor: pointer;
}

@-webkit-keyframes Navigation_transitionIn__Tj_6e {
  from {
    opacity: 0;
    -webkit-transform: rotateX(-10deg);
            transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
}

@keyframes Navigation_transitionIn__Tj_6e {
  from {
    opacity: 0;
    -webkit-transform: rotateX(-10deg);
            transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
}

body {
  background-color: #282828;
  color: white;
  font-family: 'Poppins', sans-serif;
  -webkit-animation: transitionIn 1s;
          animation: transitionIn 1s;
}

@-webkit-keyframes transitionIn {
  from {
    opacity: 0;
    -webkit-transform: rotateX(-10deg);
            transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
}

@keyframes transitionIn {
  from {
    opacity: 0;
    -webkit-transform: rotateX(-10deg);
            transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
}

